import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiUrls, post } from "utils/request";
import moment from 'moment';
import axios from 'axios';

const initialState = {
  data: {
    dataActual: [],
    dataPlan: [],
    dataProductivity: [],
    dataPlanAvg: [],
    planAccumulation: [],
    actualAccumulation: [],
    dtm: 0,
    ytd: 0,
  },
  dataProductivity: {
    dataActual: [],
    dataPlan: [],
    dataProductivity: [],
    dataPlanAvg: [],
    planAccumulation: [],
    actualAccumulation: [],
    dtm: 0,
    ytd: 0,
  },
  isLoading: false,
  error: null,
  errorMessages: '',
  date: moment().format('YYYY-MM'),
  crusherPlan: [],
  crusherPlanActive: [],
};

const removeNullValues = (obj) => {
  const entries = Object.entries(obj);
  const filteredEntries = entries.filter(([key, value]) => value !== null);

  return Object.fromEntries(filteredEntries);
};

export const getCrusherPlan = createAsyncThunk(
  "crusherPlan/get",
  async (params, thunkAPI) => {
    const payload = {
      columns: [],
      start: 0,
      draw: 1,
      length: 500,
      ...params,
    }
    const response = await axios.post(`${apiUrls.crushers}/filter`, payload);
    return response.data;
  }
);

export const getCrushingActualDashboard = createAsyncThunk(
  'DashboardProduction/getCrushingActualDashboard',
  async (params) => {
    const response = await post(
      `${apiUrls.actualPortCrushing}/dashboard/actual`, removeNullValues(params)
    );
    return response?.data;
  }
);

export const getCrushingProductivityDashboard = createAsyncThunk(
  'DashboardProduction/getCrushingProductivityDashboard',
  async (params) => {
    const response = await post(
      `${apiUrls.actualPortCrushing}/dashboard/productivity`, removeNullValues(params)
    );
    return response?.data;
  }
);

export const getConfPlanDashboard = createAsyncThunk(
  'DashboardProduction/getConfPlanDashboard',
  async (params) => {
    const response = await post(
      `${apiUrls.confCrusher}dashboard/plan`, removeNullValues(params)
    );
    return response?.data;
  }
);

const generateResultArray = (response = [], date, metrics = 'actual') => {
  const daysInMonth = moment(date, 'YYYY-MM').daysInMonth();
  const resultArray = new Array(daysInMonth).fill(0);
  const resultEwh = new Array(daysInMonth).fill(0);
  const resultProduction = new Array(daysInMonth).fill(0);
  if (metrics === 'actual') {
    response.forEach(item => {
      const index = moment(item.date).format("DD") - 1;
      resultArray[index] += item.production;
    });
  } else if (metrics === 'plan' && response.length > 0) {
    let value = 0
    for (const item of response) {
      value += item.value
    }
    const planValues = value;
    return new Array(daysInMonth).fill(planValues);
  } else if (metrics === 'productivity' && response.length > 0) {
    let value = 0
    for (const item of response) {
      value += item.productivity_target
    }
    const planValues = value;
    return new Array(daysInMonth).fill(planValues);
  } else if (metrics === 'productivityData') {
    response.forEach(item => {
      const index = moment(item).format("DD") - 1;
      resultProduction[index] += item.production !== undefined ? item.production : 0;
      resultEwh[index] += item.ewh !== undefined ? item.ewh : 0;
    });
    resultProduction.forEach((item, index) => {
      if (item === 0 || item === undefined || item === null) {
        resultArray[index] = 0
      } else {
        resultArray[index] = item / resultEwh[index];
      }
    });
  }

  return resultArray;
};

const cumulativeSum = (arr) => {
  let result = [];
  let sum = 0;
  arr.forEach((num) => {
    sum += num;
    result.push(sum);
  });
  return result;
};

const DashboardProductionSlice = createSlice({
  name: 'DashboardProduction',
  initialState,
  reducers: {
    resetState: (state) => {
      state.data = {
        dataActual: [],
        dataPlan: [],
      };
      state.isLoading = false;
      state.error = null;
      state.errorMessages = '';
    },
    resetDate: (state) => {
      state.date = moment().format('YYYY-MM');
    },
    setDate: (state, action) => {
      state.date = action.payload;
    }
  },
  extraReducers: {
    [getCrushingActualDashboard.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.errorMessages = '';
    },
    [getCrushingActualDashboard.fulfilled]: (state, action) => {
      const paramDate = `${action.meta.arg.year}-${action.meta.arg.month}`
      const data = generateResultArray(action.payload.dataList, paramDate ? paramDate : state.date);
      const dtm = action.payload.monthToDate;
      const ytd = action.payload.yearToDate;

      state.data.dtm = dtm ? dtm : 0;
      state.data.ytd = ytd ? ytd : 0;
      state.data.dataActual = data;
      state.data.dataActualRes = action.payload.dataList;
      state.data.actualAccumulation = cumulativeSum(data)
      state.isLoading = false;
      state.error = null;
      state.errorMessages = '';
    },
    [getCrushingActualDashboard.rejected]: (state, action) => {
      state.isLoading = false;
      state.data.dtm = 0;
      state.data.dataActual = [];
      state.data.dataActualRes = [];
      state.data.actualAccumulation = []
      state.error = action.error;
      state.errorMessages = action.error.message;
    },
    [getCrushingProductivityDashboard.pending]: (state) => {
      state.isLoading = false;
      state.error = null;
      state.errorMessages = '';
    },
    [getCrushingProductivityDashboard.fulfilled]: (state, action) => {
      const paramDate = `${action.meta.arg.year}-${action.meta.arg.month}`
      const data = generateResultArray(action.payload.dataList, paramDate ? paramDate : state.date, "productivityData");
      const dtm = action.payload?.monthToDate;
      const ytd = action.payload?.yearToDate;

      state.dataProductivity.dtm = dtm ? dtm : 0;
      state.dataProductivity.ytd = ytd ? ytd : 0;
      state.dataProductivity.dataActual = data;
      state.dataProductivity.dataActualRes = action.payload.dataList;
      state.dataProductivity.actualAccumulation = cumulativeSum(data)
      state.isLoading = false;
      state.error = null;
      state.errorMessages = '';
    },
    [getCrushingProductivityDashboard.rejected]: (state, action) => {
      state.isLoading = false;
      state.dataProductivity.dtm = 0;
      state.dataProductivity.dataActual = [];
      state.dataProductivity.dataActualRes = [];
      state.dataProductivity.actualAccumulation = []
      state.error = action.error;
      state.errorMessages = action.error.message;
    },
    [getConfPlanDashboard.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.errorMessages = '';
    },
    [getConfPlanDashboard.fulfilled]: (state, action) => {
      const paramDate = `${action.meta.arg.year}-${action.meta.arg.month}`
      const data = generateResultArray(action.payload, paramDate ? paramDate : state.date, 'plan');
      const productivity = generateResultArray(action.payload, paramDate ? paramDate : state.date, 'productivity');
      state.data.dataPlan = data;
      state.data.dataProductivity = productivity;
      state.dataProductivity.dataPlan = data;
      state.dataProductivity.dataProductivity = productivity;
      state.dataProductivity.dataPlanRes = action.payload;
      state.data.dataPlanRes = action.payload;
      state.data.planAccumulation = cumulativeSum(data)
      state.isLoading = false;
      state.error = null;
      state.errorMessages = '';
    },
    [getConfPlanDashboard.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.errorMessages = action.error.message;
    },
    [getCrusherPlan.fulfilled]: (state, action) => {
      const data = action.payload.list || [];
      state.crusherPlan = data.reduce((unique, item) => {
        const isDuplicate = unique.some(uniqueItem => uniqueItem.plant === item.plant);
        return isDuplicate ? unique : [...unique, { value: item.plant, label: item.plant, ...item }];
      }, []);
      state.crusherPlan.unshift({ value: null, label: 'All' });
    },
    [getCrusherPlan.rejected]: (state, action) => {
      state.error = action.error;
      state.errorMessages = action.error.message;
    }
  }
});

export const { actions } = DashboardProductionSlice;
export const { resetState, resetDate, setDate } = actions;
export const dashboardProductionSelector = (state) => state.dashboardProduction;
export default DashboardProductionSlice.reducer;
