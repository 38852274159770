import {
  ActiveSort,
  ArrowDown,
  LeftChevron,
  NotActiveSort,
  RightChevron,
  SortDown,
  SortUp,
} from "assets/icons";
import cx from "classnames";
import { useState } from "react";
import { useEffect } from "react";
import {
  useAbsoluteLayout,
  useBlockLayout,
  useExpanded,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import ReactTooltip from "react-tooltip";
import { sortItems } from "utils/helper";

import { CloseIcon } from "./Icons";

const Table = ({
  className = "primary",
  showPagination = true,
  showEntries = true,
  columns,
  data,
  responsive = true,
  startFromOne = false,
  showEmptyText = true,
  manualPagination = false,
  changePage = false,
  changeIndex = false,
  loadingFetch = null,
  totalCount,
  pageCount: controlledPageCount,
  pageSize = null,
  currentPage,
  onPageIndexChange = () => null,
  onPageSizeChange = () => ReactTooltip.rebuild(),
  onDeleteColumn = () => null,
  onChange = () => null,
  onResetIndex = () => null,
  // sorting
  sortClassName = "",
  manualSortBy = false,
  tableLayout = "flex",
  sticky = true,
  expanded = {},
  dataEmpty = false,
  resetIndex = false,
  ...props
}) => {
  const sortTypes = {
    alphanumeric: sortItems,
  };

  const [loadingTable, setLoadingTable] = useState(true);

  let tablePlugins = [useSortBy, useExpanded, usePagination];
  switch (tableLayout) {
    case "flex":
      tablePlugins.push(useFlexLayout);
      break;
    case "block":
      tablePlugins.push(useBlockLayout);
      break;
    case "absolute":
      tablePlugins.push(useAbsoluteLayout);
      break;
  }

  const tableConfig = {
    sortTypes,
    columns,
    data,
    manualPagination,
    initialState: { pageIndex: 0, expanded, pageSize: pageSize || 10 },
    autoResetExpanded: false,
    manualSortBy: manualSortBy,
    autoResetPage: false,
  }

  if (controlledPageCount) tableConfig.pageCount = controlledPageCount

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    page, // page for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize: tablePageSize, sortBy },
  } = useTable(
    tableConfig,
    useSticky,
    ...tablePlugins
  );

  useEffect(() => {
    if (resetIndex) {
      gotoPage(0)
      onResetIndex(false)
    }
  }, [resetIndex]);

  useEffect(() => {
    onPageIndexChange(pageIndex);
    if (changeIndex) changeIndex(pageIndex + 1)
  }, [onPageIndexChange, pageIndex]);

  useEffect(() => {
    onPageSizeChange(tablePageSize);
  }, [onPageSizeChange, tablePageSize]);

  useEffect(() => {
    onChange({ pageIndex, pageSize: tablePageSize, sortBy });
  }, [pageIndex, tablePageSize, sortBy]);

  useEffect(() => {
    if (data.length) {
      setLoadingTable(false);
    } else {
      setTimeout(() => {
        setLoadingTable(false);
      }, 5000);
    }
  }, [data]);

  const totalPages = pageOptions.length;

  const startPageIndex = startFromOne ? 1 : 0;
  let startPage, endPage;
  if (totalPages <= 10) {
    // less than 10 total pages so show all
    startPage = startPageIndex;
    endPage = totalPages;
  } else {
    // more than 10 total pages so calculate start and end pages
    if (pageIndex <= 6) {
      startPage = startPageIndex;
      endPage = 9;
    } else if (pageIndex + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = pageIndex - 5;
      endPage = pageIndex + 4;
    }
  }

  const handleShowEntryChange = (e) => {
    const pageSize_ = Number(e.target.value);
    if (changePage) changePage(pageSize_)
    setPageSize(pageSize_);
  };

  return (
    <div className="table-component-wrapper">
      <ReactTooltip
        place="bottom"
        effect="solid"
        border={true}
        borderColor="#000"
        textColor="#000"
        backgroundColor="#fff"
      />
      <div className={responsive ? "table-responsive" : ""}>
        <table {...getTableProps()} className={`table ${className}`}>
          <thead className={sticky ? "sticky" : ""}>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="sticky-header"
              >
                {headerGroup.headers.map((column) => (
                  // <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <th
                    {...column.getHeaderProps([
                      {
                        className: column.className,
                        style: column.style,
                      },
                      // getColumnProps(column),
                      // getHeaderProps(column),
                      column.getSortByToggleProps(),
                    ])}
                  >
                    {column.render("Header")}
                    {!column.disableSortBy &&
                      (column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className={`sort active ${sortClassName}`}>
                            <SortUp />
                          </span>
                        ) : (
                          <span className={`sort active ${sortClassName}`}>
                            <SortDown />
                          </span>
                        )
                      ) : (
                        <span className={`sort ${sortClassName}`}>
                          <NotActiveSort />
                        </span>
                      ))}
                    {column?.deleteColumn && (
                      <div
                        className="delete-column"
                        onClick={() => onDeleteColumn(column.id)}
                      >
                        <CloseIcon variant="gray" />
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {(!dataEmpty && !loadingFetch) && (
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                const canExpanded = row.canExpand;
                return (
                  <tr
                    {...row.getRowProps()}
                    className={`${canExpanded && "expanded"}`}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          // Return an array of prop objects and react-table will merge them appropriately
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {!dataEmpty && (
          <>
            {!loadingFetch ? null : (
              <div className="empty-data flex-center">Loading...</div>
            )}

            {(!loadingTable || loadingFetch) ? null : (
              <div className="empty-data flex-center">Loading...</div>
            )}

            {loadingFetch ? null : loadingTable ? (
              false
            ) : data?.length ? null : !showEmptyText ? null : (
              <div className="empty-data flex-center">Empty data</div>
            )}

            {/* {!loadingFetch || loadingTable ? (
              false
            ) : data?.length ? null : !showEmptyText ? null : (
              <div className="empty-data flex-center">Loading...</div>
            )} */}
          </>
        )}
      </div>

      {!manualPagination || (manualPagination && totalCount) ? (
        <>
          {showPagination && (
            <div className="wrapper-pagination">
              {showEntries && (
                <div className="option-select">
                  <span className="mr-5">Show</span>
                  <div className="wrapper-select">
                    <select value={pageSize} onChange={handleShowEntryChange}>
                      {[10, 20, 30, 40, 50].map((pageSize_) => (
                        <option key={pageSize_} value={pageSize_}>
                          {pageSize_}
                        </option>
                      ))}
                    </select>
                    <ArrowDown />
                  </div>
                  <span className="ml-5">Entries</span>
                </div>
              )}

              <div className="pagination">
                <div
                  className={`pagination-prev ${!canPreviousPage && "disabled"
                    }`}
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <LeftChevron />
                </div>
                {Array.from({ length: endPage - startPage }).map((_, index) => {
                  const currentPageIndex = startPage + index;
                  return (
                    <div
                      key={`page-${currentPageIndex}`}
                      className={cx("pagination-page", {
                        active: currentPageIndex === pageIndex,
                      })}
                      onClick={() => gotoPage(currentPageIndex)}
                    >
                      {currentPageIndex + 1}
                    </div>
                  );
                })}
                <div
                  className={`pagination-next ${!canNextPage && "disabled"}`}
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <RightChevron />
                </div>
              </div>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default Table;
