import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiUrls, get, post, put } from 'utils/request';
import moment from 'moment';
import { toast } from 'react-toastify';

export const initialState = {
  // weather
  summaryCoalChain: {
    records: [],
    loading: false,
    error: null
  },
  summaryWeatherActual: {
    records: [],
    loading: false,
    error: null
  },
  summaryWeatherActualDetail: {
    records: [],
    loading: false,
    error: null
  },
  summaryWeatherForecastBeforeReportDate: {
    records: null,
    loading: false,
    error: null
  },
  summaryWeatherForecast: {
    records: null,
    loading: false,
    error: null
  },
  summaryCoalToMine: {
    records: [],
    loading: false,
    error: null
  },
  summaryPitToRom: {
    records: [],
    loading: false,
    error: null
  },
  summaryPitToPort: {
    records: [],
    loading: false,
    error: null
  },
  summaryRomToPort: {
    records: [],
    loading: false,
    error: null
  },
  fleetFromPit: {
    records: [],
    loading: false,
    error: null
  },
  summaryFleetFromPit: {
    records: [],
    loading: false,
    error: null
  },
  haulingFleet: {
    records: null,
    loading: false,
    error: null
  },
  summaryHaulingFleet: {
    records: null,
    loading: false,
    error: null
  },
  planCoalFleet: {
    records: null,
    loading: false,
    error: null
  },
  summaryPlanCoalFleet: {
    records: null,
    loading: false,
    error: null
  },
  summaryRomStockpile: {
    records: [],
    loading: false,
    error: null
  },
  summaryPortStockpile: {
    records: [],
    loading: false,
    error: null
  },
  summaryBargeStatus: {
    records: [],
    loading: false,
    error: null
  },
  summaryBargeActual: {
    records: [],
    loading: false,
    error: null
  },
  summaryBargePlan: {
    records: null,
    loading: false,
    error: null
  },
  summaryBargePlanDetail: {
    records: [],
    loading: false,
    error: null
  },
  summaryBargeLoadProgress: {
    records: [],
    loading: false,
    error: null
  },
  // transshipment
  // mtdbl ytdbl
  summaryTransshipmentActualMTDBLYTDBL: {
    records: null,
    loading: true,
    error: null,
  },
  summaryTransshipmentActualInRangeBeforeMTDBLYTDBL: {
    records: null,
    loading: true,
    error: null,
  },
  summaryTransshipmentActualInRangeMTDBLYTDBL: {
    records: null,
    loading: true,
    error: null,
  },
  summaryTransshipmentActualInRangeYearMTDBLYTDBL: {
    records: null,
    loading: true,
    error: null,
  },
  // end mtdbl ytdbl
  summaryTransshipmentActualBillOfLading: {
    records: null,
    loading: false,
    error: null
  },
  summaryTransshipmentActual: {
    records: null,
    loading: false,
    error: null
  },
  summaryTransshipmentActualDetail: {
    records: [],
    loading: false,
    error: null
  },
  summaryTransshipmentNext: {
    records: null,
    loading: false,
    error: null
  },
  summaryTransshipmentNextDetail: {
    records: [],
    loading: false,
    error: null
  },
  summaryTransshipmentFloatingCrane: {
    records: null,
    loading: false,
    error: null
  },
  summaryTransshipmentFloatingCraneDetail: {
    records: [],
    loading: false,
    error: null
  },
  summaryTransshipmentForecast: {
    records: [],
    loading: false,
    error: null
  },
  summaryTransshipmentForecastDetail: {
    records: [],
    loading: false,
    error: null
  },

  // mtd
  summaryMTDWeatherActual: {
    records: [],
    loading: false,
    error: null
  },
  summaryMTDWeatherActualDetail: {
    records: [],
    loading: false,
    error: null
  },
  summaryMTDWeatherForecast: {
    records: [],
    loading: false,
    error: null
  },
  report: {
    records: [],
    loading: false,
    error: null
  },
}

export const yesterdayDate = moment().subtract(1, 'days').format("YYYY-MM-DD");

const handleErrorMessage = (msg) => {
  return toast.error(msg, {
    position: toast.POSITION.BOTTOM_RIGHT
  });
}

export const getSummaryCoalChain = createAsyncThunk(
  'executive-report/summary/production/coal-chain-chart',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/coal-chain-chart?is_published=true&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/coal-chain-chart?is_published=true&worked_at=${params?.date ?? yesterdayDate}`;

    const response = await get(requestUrl).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

// summary weather
// weather actual
export const getSummaryWeatherActual = createAsyncThunk(
  'executive-report/summary/production/weather-actual',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/production/weather-actual?is_published=true&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/production/weather-actual?is_published=true&worked_at=${params?.date ?? yesterdayDate}`

    const response = await get(`${requestUrl}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);
export const getSummaryWeatherActualDetail = createAsyncThunk(
  'executive-report/summary/production/weather-actual-detail',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/production/weather-actual-detail?is_published=true&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/production/weather-actual-detail?is_published=true&worked_at=${params?.date ?? yesterdayDate}`

    const response = await get(`${requestUrl}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

// weather forecast
export const getSummaryWeatherForecastBeforeReportDate = createAsyncThunk(
  'executive-report/summary/production/weather-forecast/before-report-date',
  async (params) => {
    let dt = new Date(params?.date ?? yesterdayDate);
    const newDt = moment(dt).subtract(1, 'days').format("YYYY-MM-DD");
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/production/weather-forecast?is_published=true&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/production/weather-forecast?is_published=true&worked_at=${newDt}`

    const response = await get(`${requestUrl}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);
export const getSummaryWeatherForecast = createAsyncThunk(
  'executive-report/summary/production/weather-forecast',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/production/weather-forecast?is_published=true&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/production/weather-forecast?is_published=true&worked_at=${params?.date ?? yesterdayDate}`

    const response = await get(`${requestUrl}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryCoalToMine = createAsyncThunk(
  'executive-report/summary/production/coal-to-mine',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/production/coal-to-mine?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryPitToRom = createAsyncThunk(
  'executive-report/summary/production/pit-to-rom',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/production/pit-to-rom?is_published=true&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/production/pit-to-rom?is_published=true&worked_at=${params?.date ?? yesterdayDate}`;

    const response = await get(requestUrl).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryPitToPort = createAsyncThunk(
  'executive-report/summary/production/pit-to-port',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/production/pit-to-port?is_published=true&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/production/pit-to-port?is_published=true&worked_at=${params?.date ?? yesterdayDate}`;

    const response = await get(requestUrl).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryRomToPort = createAsyncThunk(
  'executive-report/summary/production/rom-to-port',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/production/rom-to-port?is_published=true&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/production/rom-to-port?is_published=true&worked_at=${params?.date ?? yesterdayDate}`;

    const response = await get(requestUrl).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getFleetFromPit = createAsyncThunk(
  'executive-report/production/fleet-from-pit',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/production/fleet-from-pit?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryFleetFromPit = createAsyncThunk(
  'executive-report/summary/production/fleet-from-pit',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/production/fleet-from-pit?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getHaulingFleet = createAsyncThunk(
  'executive-report/production/hauling-fleet',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/production/hauling-fleet?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryHaulingFleet = createAsyncThunk(
  'executive-report/summary/production/hauling-fleet',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/production/hauling-fleet?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getPlanCoalFleet = createAsyncThunk(
  'executive-report/production/plan-coal-fleet',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/production/plan-coal-fleet?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryPlanCoalFleet = createAsyncThunk(
  'executive-report/summary/production/plan-coal-fleet',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/production/plan-coal-fleet?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryRomStockpile = createAsyncThunk(
  'executive-report/summary/stockpile/rom',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/stockpile/rom?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryPortStockpile = createAsyncThunk(
  'executive-report/summary/stockpile/port',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/stockpile/port?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryBargeActual = createAsyncThunk(
  'executive-report/summary/barging/actual',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/barging/actual?is_published=true&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/barging/actual?is_published=true&worked_at=${params?.date ?? yesterdayDate}`;

    const response = await get(requestUrl).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryBargePlan = createAsyncThunk(
  'executive-report/summary/barging/plan',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/barging/plan?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);
export const getSummaryBargePlanDetail = createAsyncThunk(
  'executive-report/summary/barging/plan-detail',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/barging/plan-detail?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryBargeStatus = createAsyncThunk(
  'executive-report/summary/barging/barge-status',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/barging/barge-status?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryBargeLoadProgress = createAsyncThunk(
  'executive-report/summary/barging/barge-progress-load',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/barging/barge-progress-load?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryTransshipmentActualMTDBLYTDBL = createAsyncThunk(
  'executiv-report/activity/summary/transshipment/actual/mtdbl-ytdbl',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/actual/mtdbl-ytdbl?is_published=true&start_worked_at=${param?.start_date ?? yesterdayDate}&end_worked_at=${param?.end_date ?? yesterdayDate}`);

    return response?.data;
  }
);
export const getSummaryTransshipmentActualInRangeBeforeMTDBLYTDBL = createAsyncThunk(
  'executiv-report/activity/summary/transshipment/actual-in-range-before/mtdbl-ytdbl',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/actual/mtdbl-ytdbl?is_published=true&start_worked_at=${param?.start_date ?? yesterdayDate}&end_worked_at=${param?.end_date ?? yesterdayDate}`);

    return response?.data;
  }
);
export const getSummaryTransshipmentActualInRangeMTDBLYTDBL = createAsyncThunk(
  'executiv-report/activity/summary/transshipment/actual-in-range/mtdbl-ytdbl',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/actual/mtdbl-ytdbl?is_published=true&start_worked_at=${param?.start_date ?? yesterdayDate}&end_worked_at=${param?.end_date ?? yesterdayDate}`);

    return response?.data;
  }
);
export const getSummaryTransshipmentActualInRangeYearMTDBLYTDBL = createAsyncThunk(
  'executiv-report/activity/summary/transshipment/actual-in-range-year/mtdbl-ytdbl',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/actual/mtdbl-ytdbl?is_published=true&start_worked_at=${param?.start_date ?? yesterdayDate}&end_worked_at=${param?.end_date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const getSummaryTransshipmentActualBillOfLading = createAsyncThunk(
  'executive-report/summary/transshipment/actual/bill-of-lading',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/transshipment/actual?start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/transshipment/actual?worked_at=${params?.date ?? yesterdayDate}`;

    const response = await get(requestUrl).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);
export const getSummaryTransshipmentActual = createAsyncThunk(
  'executive-report/summary/transshipment/actual',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/transshipment/actual?is_published=true&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/transshipment/actual?is_published=true&worked_at=${params?.date ?? yesterdayDate}`;

    const response = await get(requestUrl).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);
export const getSummaryTransshipmentActualDetail = createAsyncThunk(
  'executive-report/summary/transshipment/actual-detail',
  async (params) => {
    const requestUrl = (params?.start_date && params?.end_date) ?
      `${apiUrls.executiveReport}/summary/transshipment/actual-detail?is_published=true&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/transshipment/actual-detail?is_published=true&worked_at=${params?.date ?? yesterdayDate}`;

    const response = await get(requestUrl).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryTransshipmentNext = createAsyncThunk(
  'executive-report/summary/transshipment/next',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/transshipment/next?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);
export const getSummaryTransshipmentNextDetail = createAsyncThunk(
  'executive-report/summary/transshipment/next-detail',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/transshipment/next-detail?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryTransshipmentFloatingCrane = createAsyncThunk(
  'executive-report/summary/transshipment/floating-crane',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/transshipment/floating-crane?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);
export const getSummaryTransshipmentFloatingCraneDetail = createAsyncThunk(
  'executive-report/summary/transshipment/floating-crane-detail',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/transshipment/floating-crane-detail?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryTransshipmentForecast = createAsyncThunk(
  'executive-report/summary/transshipment/forecast',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/transshipment/forecast?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);
export const getSummaryTransshipmentForecastDetail = createAsyncThunk(
  'executive-report/summary/transshipment/forecast-detail',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/transshipment/forecast-detail?is_published=true&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

// MTD
export const getSummaryMTDWeatherActual = createAsyncThunk(
  'executive-report/summary/mtd/production/weather-actual',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/production/weather-actual?is_published=true&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/production/weather-actual?is_published=true&worked_at=${params?.date ?? yesterdayDate}`

    const response = await get(`${requestUrl}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryMTDWeatherActualDetail = createAsyncThunk(
  'executive-report/summary/mtd/production/weather-actual-detail',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/production/weather-actual-detail?is_published=true&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/production/weather-actual-detail?is_published=true&worked_at=${params?.date ?? yesterdayDate}`

    const response = await get(`${requestUrl}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

// weather forecast
export const getSummaryMTDWeatherForecast = createAsyncThunk(
  'executive-report/summary/mtd/production/weather-forecast',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/production/weather-forecast?is_published=true&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/production/weather-forecast?is_published=true&worked_at=${params?.date ?? yesterdayDate}`

    const response = await get(`${requestUrl}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getReport = createAsyncThunk(
  'executive-report/report',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/report?report_date=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);


const summarySlice = createSlice({
  name: 'SUMMARY_REDUCER',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSummaryCoalChain.pending, (state, action) => {
      state.summaryCoalChain.loading = true;
    });
    builder.addCase(getSummaryCoalChain.fulfilled, (state, action) => {
      state.summaryCoalChain.records = action.payload || [];
      state.summaryCoalChain.loading = false;
    });
    builder.addCase(getSummaryCoalChain.rejected, (state, action) => {
      state.summaryCoalChain.loading = false;
    });
    // weather
    // weather actual
    builder.addCase(getSummaryWeatherActual.pending, (state, action) => {
      state.summaryWeatherActual.loading = true;
    });
    builder.addCase(getSummaryWeatherActual.fulfilled, (state, action) => {
      state.summaryWeatherActual.records = action.payload || [];
      state.summaryWeatherActual.loading = false;
    });
    builder.addCase(getSummaryWeatherActual.rejected, (state, action) => {
      state.summaryWeatherActual.loading = false;
    });
    builder.addCase(getSummaryWeatherActualDetail.pending, (state, action) => {
      state.summaryWeatherActualDetail.loading = true;
    });
    builder.addCase(getSummaryWeatherActualDetail.fulfilled, (state, action) => {
      state.summaryWeatherActualDetail.records = action.payload || [];
      state.summaryWeatherActualDetail.loading = false;
    });
    builder.addCase(getSummaryWeatherActualDetail.rejected, (state, action) => {
      state.summaryWeatherActualDetail.loading = false;
    });
    // weather forecast
    builder.addCase(getSummaryWeatherForecastBeforeReportDate.pending, (state, action) => {
      state.summaryWeatherForecastBeforeReportDate.loading = true;
    });
    builder.addCase(getSummaryWeatherForecastBeforeReportDate.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryWeatherForecastBeforeReportDate.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryWeatherForecastBeforeReportDate.loading = false;
    });
    builder.addCase(getSummaryWeatherForecastBeforeReportDate.rejected, (state, action) => {
      state.summaryWeatherForecastBeforeReportDate.loading = false;
    });
    builder.addCase(getSummaryWeatherForecast.pending, (state, action) => {
      state.summaryWeatherForecast.loading = true;
    });
    builder.addCase(getSummaryWeatherForecast.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryWeatherForecast.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryWeatherForecast.loading = false;
    });
    builder.addCase(getSummaryWeatherForecast.rejected, (state, action) => {
      state.summaryWeatherForecast.loading = false;
    });
    // coal to mine
    builder.addCase(getSummaryCoalToMine.pending, (state, action) => {
      state.summaryCoalToMine.loading = true;
    });
    builder.addCase(getSummaryCoalToMine.fulfilled, (state, action) => {
      state.summaryCoalToMine.records = action.payload || [];
      state.summaryCoalToMine.loading = false;
    });
    builder.addCase(getSummaryCoalToMine.rejected, (state, action) => {
      state.summaryCoalToMine.loading = false;
    });
    // pit to Rom
    builder.addCase(getSummaryPitToRom.pending, (state, action) => {
      state.summaryPitToRom.loading = true;
    });
    builder.addCase(getSummaryPitToRom.fulfilled, (state, action) => {
      state.summaryPitToRom.records = action.payload || [];
      state.summaryPitToRom.loading = false;
    });
    builder.addCase(getSummaryPitToRom.rejected, (state, action) => {
      state.summaryPitToRom.loading = false;
    });
    // pit to port
    builder.addCase(getSummaryPitToPort.pending, (state, action) => {
      state.summaryPitToPort.loading = true;
    });
    builder.addCase(getSummaryPitToPort.fulfilled, (state, action) => {
      state.summaryPitToPort.records = action.payload || [];
      state.summaryPitToPort.loading = false;
    });
    builder.addCase(getSummaryPitToPort.rejected, (state, action) => {
      state.summaryPitToPort.loading = false;
    });
    // rom to port
    builder.addCase(getSummaryRomToPort.pending, (state, action) => {
      state.summaryRomToPort.loading = true;
    });
    builder.addCase(getSummaryRomToPort.fulfilled, (state, action) => {
      state.summaryRomToPort.records = action.payload || [];
      state.summaryRomToPort.loading = false;
    });
    builder.addCase(getSummaryRomToPort.rejected, (state, action) => {
      state.summaryRomToPort.loading = false;
    });

    // Actual Coal Fleet
    // fleet from pit
    builder.addCase(getFleetFromPit.pending, (state, action) => {
      state.fleetFromPit.loading = true;
    });
    builder.addCase(getFleetFromPit.fulfilled, (state, action) => {
      state.fleetFromPit.records = action.payload || [];
      state.fleetFromPit.loading = false;
    });
    builder.addCase(getFleetFromPit.rejected, (state, action) => {
      state.fleetFromPit.loading = false;
    });
    builder.addCase(getSummaryFleetFromPit.pending, (state, action) => {
      state.summaryFleetFromPit.loading = true;
    });
    builder.addCase(getSummaryFleetFromPit.fulfilled, (state, action) => {
      state.summaryFleetFromPit.records = action.payload || [];
      state.summaryFleetFromPit.loading = false;
    });
    builder.addCase(getSummaryFleetFromPit.rejected, (state, action) => {
      state.summaryFleetFromPit.loading = false;
    });
    // hauling fleet
    builder.addCase(getHaulingFleet.pending, (state, action) => {
      state.haulingFleet.loading = true;
    });
    builder.addCase(getHaulingFleet.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.haulingFleet.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.haulingFleet.loading = false;
    });
    builder.addCase(getHaulingFleet.rejected, (state, action) => {
      state.haulingFleet.loading = false;
    });
    builder.addCase(getSummaryHaulingFleet.pending, (state, action) => {
      state.summaryHaulingFleet.loading = true;
    });
    builder.addCase(getSummaryHaulingFleet.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryHaulingFleet.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryHaulingFleet.loading = false;
    });
    builder.addCase(getSummaryHaulingFleet.rejected, (state, action) => {
      state.summaryHaulingFleet.loading = false;
    });

    // plan coal fleet
    builder.addCase(getPlanCoalFleet.pending, (state, action) => {
      state.planCoalFleet.loading = true;
    });
    builder.addCase(getPlanCoalFleet.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.planCoalFleet.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.planCoalFleet.loading = false;
    });
    builder.addCase(getPlanCoalFleet.rejected, (state, action) => {
      state.planCoalFleet.loading = false;
    });
    builder.addCase(getSummaryPlanCoalFleet.pending, (state, action) => {
      state.summaryPlanCoalFleet.loading = true;
    });
    builder.addCase(getSummaryPlanCoalFleet.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryPlanCoalFleet.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryPlanCoalFleet.loading = false;
    });
    builder.addCase(getSummaryPlanCoalFleet.rejected, (state, action) => {
      state.summaryPlanCoalFleet.loading = false;
    });
    // stockpile
    // rom stockpile
    builder.addCase(getSummaryRomStockpile.pending, (state, action) => {
      state.summaryRomStockpile.loading = true;
    });
    builder.addCase(getSummaryRomStockpile.fulfilled, (state, action) => {
      state.summaryRomStockpile.records = action.payload || [];
      state.summaryRomStockpile.loading = false;
    });
    builder.addCase(getSummaryRomStockpile.rejected, (state, action) => {
      state.summaryRomStockpile.loading = false;
    });
    // port stockpile
    builder.addCase(getSummaryPortStockpile.pending, (state, action) => {
      state.summaryPortStockpile.loading = true;
    });
    builder.addCase(getSummaryPortStockpile.fulfilled, (state, action) => {
      state.summaryPortStockpile.records = action.payload || [];
      state.summaryPortStockpile.loading = false;
    });
    builder.addCase(getSummaryPortStockpile.rejected, (state, action) => {
      state.summaryPortStockpile.loading = false;
    });
    // barge
    // barge actual
    builder.addCase(getSummaryBargeActual.pending, (state, action) => {
      state.summaryBargeActual.loading = true;
    });
    builder.addCase(getSummaryBargeActual.fulfilled, (state, action) => {
      state.summaryBargeActual.records = action.payload || [];
      state.summaryBargeActual.loading = false;
    });
    builder.addCase(getSummaryBargeActual.rejected, (state, action) => {
      state.summaryBargeActual.loading = false;
    });
    // barge plan
    builder.addCase(getSummaryBargePlan.pending, (state, action) => {
      state.summaryBargePlan.loading = true;
    });
    builder.addCase(getSummaryBargePlan.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryBargePlan.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryBargePlan.loading = false;
    });
    builder.addCase(getSummaryBargePlan.rejected, (state, action) => {
      state.summaryBargePlan.loading = false;
    });
    builder.addCase(getSummaryBargePlanDetail.pending, (state, action) => {
      state.summaryBargePlanDetail.loading = true;
    });
    builder.addCase(getSummaryBargePlanDetail.fulfilled, (state, action) => {
      state.summaryBargePlanDetail.records = action.payload || [];
      state.summaryBargePlanDetail.loading = false;
    });
    builder.addCase(getSummaryBargePlanDetail.rejected, (state, action) => {
      state.summaryBargePlanDetail.loading = false;
    });
    // barge status & load progress
    builder.addCase(getSummaryBargeStatus.pending, (state, action) => {
      state.summaryBargeStatus.loading = true;
    });
    builder.addCase(getSummaryBargeStatus.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryBargeStatus.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryBargeStatus.loading = false;
    });
    builder.addCase(getSummaryBargeStatus.rejected, (state, action) => {
      state.summaryBargeStatus.loading = false;
    });
    builder.addCase(getSummaryBargeLoadProgress.pending, (state, action) => {
      state.summaryBargeLoadProgress.loading = true;
    });
    builder.addCase(getSummaryBargeLoadProgress.fulfilled, (state, action) => {
      state.summaryBargeLoadProgress.records = action.payload || [];
      state.summaryBargeLoadProgress.loading = false;
    });
    builder.addCase(getSummaryBargeLoadProgress.rejected, (state, action) => {
      state.summaryBargeLoadProgress.loading = false;
    });
    // transshipment
    // transshipment actual
    // MTDBL YTDBL
    builder.addCase(getSummaryTransshipmentActualMTDBLYTDBL.pending, (state, action) => {
      state.summaryTransshipmentActualMTDBLYTDBL.loading = true;
      state.summaryTransshipmentActualMTDBLYTDBL.records = null;
    });
    builder.addCase(getSummaryTransshipmentActualMTDBLYTDBL.fulfilled, (state, action) => {
      state.summaryTransshipmentActualMTDBLYTDBL.records = action?.payload ?? null;
      state.summaryTransshipmentActualMTDBLYTDBL.loading = false;
    });
    builder.addCase(getSummaryTransshipmentActualMTDBLYTDBL.rejected, (state, action) => {
      state.summaryTransshipmentActualMTDBLYTDBL.loading = false;
      state.summaryTransshipmentActualMTDBLYTDBL.records = null;
    });
    builder.addCase(getSummaryTransshipmentActualInRangeBeforeMTDBLYTDBL.pending, (state, action) => {
      state.summaryTransshipmentActualInRangeBeforeMTDBLYTDBL.loading = true;
      state.summaryTransshipmentActualInRangeBeforeMTDBLYTDBL.records = null;
    });
    builder.addCase(getSummaryTransshipmentActualInRangeBeforeMTDBLYTDBL.fulfilled, (state, action) => {
      state.summaryTransshipmentActualInRangeBeforeMTDBLYTDBL.records = action?.payload ?? null;
      state.summaryTransshipmentActualInRangeBeforeMTDBLYTDBL.loading = false;
    });
    builder.addCase(getSummaryTransshipmentActualInRangeBeforeMTDBLYTDBL.rejected, (state, action) => {
      state.summaryTransshipmentActualInRangeBeforeMTDBLYTDBL.loading = false;
      state.summaryTransshipmentActualInRangeBeforeMTDBLYTDBL.records = null;
    });
    builder.addCase(getSummaryTransshipmentActualInRangeMTDBLYTDBL.pending, (state, action) => {
      state.summaryTransshipmentActualInRangeMTDBLYTDBL.loading = true;
      state.summaryTransshipmentActualInRangeMTDBLYTDBL.records = null;
    });
    builder.addCase(getSummaryTransshipmentActualInRangeMTDBLYTDBL.fulfilled, (state, action) => {
      state.summaryTransshipmentActualInRangeMTDBLYTDBL.records = action?.payload ?? null;
      state.summaryTransshipmentActualInRangeMTDBLYTDBL.loading = false;
    });
    builder.addCase(getSummaryTransshipmentActualInRangeMTDBLYTDBL.rejected, (state, action) => {
      state.summaryTransshipmentActualInRangeMTDBLYTDBL.loading = false;
      state.summaryTransshipmentActualInRangeMTDBLYTDBL.records = null;
    });
    builder.addCase(getSummaryTransshipmentActualInRangeYearMTDBLYTDBL.pending, (state, action) => {
      state.summaryTransshipmentActualInRangeYearMTDBLYTDBL.loading = true;
      state.summaryTransshipmentActualInRangeYearMTDBLYTDBL.records = null;
    });
    builder.addCase(getSummaryTransshipmentActualInRangeYearMTDBLYTDBL.fulfilled, (state, action) => {
      state.summaryTransshipmentActualInRangeYearMTDBLYTDBL.records = action?.payload ?? null;
      state.summaryTransshipmentActualInRangeYearMTDBLYTDBL.loading = false;
    });
    builder.addCase(getSummaryTransshipmentActualInRangeYearMTDBLYTDBL.rejected, (state, action) => {
      state.summaryTransshipmentActualInRangeYearMTDBLYTDBL.loading = false;
      state.summaryTransshipmentActualInRangeYearMTDBLYTDBL.records = null;
    });
    // getSummaryTransshipmentActualBillOfLading
    builder.addCase(getSummaryTransshipmentActualBillOfLading.pending, (state, action) => {
      state.summaryTransshipmentActualBillOfLading.loading = true;
    });
    builder.addCase(getSummaryTransshipmentActualBillOfLading.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryTransshipmentActualBillOfLading.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryTransshipmentActualBillOfLading.loading = false;
    });
    builder.addCase(getSummaryTransshipmentActualBillOfLading.rejected, (state, action) => {
      state.summaryTransshipmentActualBillOfLading.loading = false;
    });
    builder.addCase(getSummaryTransshipmentActual.pending, (state, action) => {
      state.summaryTransshipmentActual.loading = true;
    });
    builder.addCase(getSummaryTransshipmentActual.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryTransshipmentActual.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryTransshipmentActual.loading = false;
    });
    builder.addCase(getSummaryTransshipmentActual.rejected, (state, action) => {
      state.summaryTransshipmentActual.loading = false;
    });
    builder.addCase(getSummaryTransshipmentActualDetail.pending, (state, action) => {
      state.summaryTransshipmentActualDetail.loading = true;
    });
    builder.addCase(getSummaryTransshipmentActualDetail.fulfilled, (state, action) => {
      state.summaryTransshipmentActualDetail.records = action.payload || [];
      state.summaryTransshipmentActualDetail.loading = false;
    });
    builder.addCase(getSummaryTransshipmentActualDetail.rejected, (state, action) => {
      state.summaryTransshipmentActualDetail.loading = false;
    });
    builder.addCase(getSummaryTransshipmentNext.pending, (state, action) => {
      state.summaryTransshipmentNext.loading = true;
    });
    builder.addCase(getSummaryTransshipmentNext.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryTransshipmentNext.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryTransshipmentNext.loading = false;
    });
    builder.addCase(getSummaryTransshipmentNext.rejected, (state, action) => {
      state.summaryTransshipmentNext.loading = false;
    });
    builder.addCase(getSummaryTransshipmentNextDetail.pending, (state, action) => {
      state.summaryTransshipmentNextDetail.loading = true;
    });
    builder.addCase(getSummaryTransshipmentNextDetail.fulfilled, (state, action) => {
      state.summaryTransshipmentNextDetail.records = action.payload || [];
      state.summaryTransshipmentNextDetail.loading = false;
    });
    builder.addCase(getSummaryTransshipmentNextDetail.rejected, (state, action) => {
      state.summaryTransshipmentNextDetail.loading = false;
    });
    builder.addCase(getSummaryTransshipmentFloatingCrane.pending, (state, action) => {
      state.summaryTransshipmentFloatingCrane.loading = true;
    });
    builder.addCase(getSummaryTransshipmentFloatingCrane.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryTransshipmentFloatingCrane.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryTransshipmentFloatingCrane.loading = false;
    });
    builder.addCase(getSummaryTransshipmentFloatingCrane.rejected, (state, action) => {
      state.summaryTransshipmentFloatingCrane.loading = false;
    });
    builder.addCase(getSummaryTransshipmentFloatingCraneDetail.pending, (state, action) => {
      state.summaryTransshipmentFloatingCraneDetail.loading = true;
    });
    builder.addCase(getSummaryTransshipmentFloatingCraneDetail.fulfilled, (state, action) => {
      state.summaryTransshipmentFloatingCraneDetail.records = action.payload || [];
      state.summaryTransshipmentFloatingCraneDetail.loading = false;
    });
    builder.addCase(getSummaryTransshipmentFloatingCraneDetail.rejected, (state, action) => {
      state.summaryTransshipmentFloatingCraneDetail.loading = false;
    });
    builder.addCase(getSummaryTransshipmentForecast.pending, (state, action) => {
      state.summaryTransshipmentForecast.loading = true;
    });
    builder.addCase(getSummaryTransshipmentForecast.fulfilled, (state, action) => {
      state.summaryTransshipmentForecast.records = action.payload || [];
      state.summaryTransshipmentForecast.loading = false;
    });
    builder.addCase(getSummaryTransshipmentForecast.rejected, (state, action) => {
      state.summaryTransshipmentForecast.loading = false;
    });
    builder.addCase(getSummaryTransshipmentForecastDetail.pending, (state, action) => {
      state.summaryTransshipmentForecastDetail.loading = true;
    });
    builder.addCase(getSummaryTransshipmentForecastDetail.fulfilled, (state, action) => {
      state.summaryTransshipmentForecastDetail.records = action.payload || [];
      state.summaryTransshipmentForecastDetail.loading = false;
    });
    builder.addCase(getSummaryTransshipmentForecastDetail.rejected, (state, action) => {
      state.summaryTransshipmentForecastDetail.loading = false;
    });

    // MTD
    builder.addCase(getSummaryMTDWeatherActual.pending, (state, action) => {
      state.summaryMTDWeatherActual.loading = true;
    });
    builder.addCase(getSummaryMTDWeatherActual.fulfilled, (state, action) => {
      state.summaryMTDWeatherActual.records = action.payload || [];
      state.summaryMTDWeatherActual.loading = false;
    });
    builder.addCase(getSummaryMTDWeatherActual.rejected, (state, action) => {
      state.summaryMTDWeatherActual.loading = false;
    });
    builder.addCase(getSummaryMTDWeatherActualDetail.pending, (state, action) => {
      state.summaryMTDWeatherActualDetail.loading = true;
    });
    builder.addCase(getSummaryMTDWeatherActualDetail.fulfilled, (state, action) => {
      state.summaryMTDWeatherActualDetail.records = action.payload || [];
      state.summaryMTDWeatherActualDetail.loading = false;
    });
    builder.addCase(getSummaryMTDWeatherActualDetail.rejected, (state, action) => {
      state.summaryMTDWeatherActualDetail.loading = false;
    });
    // weather forecast
    builder.addCase(getSummaryMTDWeatherForecast.pending, (state, action) => {
      state.summaryMTDWeatherForecast.loading = true;
    });
    builder.addCase(getSummaryMTDWeatherForecast.fulfilled, (state, action) => {
      state.summaryMTDWeatherForecast.records = action.payload || [];
      state.summaryMTDWeatherForecast.loading = false;
    });
    builder.addCase(getSummaryMTDWeatherForecast.rejected, (state, action) => {
      state.summaryMTDWeatherForecast.loading = false;
    });
    builder.addCase(getReport.pending, (state, action) => {
      state.report.loading = true;
    });
    builder.addCase(getReport.fulfilled, (state, action) => {
      state.report.records = action.payload || [];
      state.report.loading = false;
    });
    builder.addCase(getReport.rejected, (state, action) => {
      state.report.loading = false;
    });
  },
});

// coal chain chart
export const summaryCoalChainSelector = (state) => state.executiveReportDashboardReducer.summaryCoalChain;
// production weather
export const summaryWeatherActualSelector = (state) => state.executiveReportDashboardReducer.summaryWeatherActual;
export const summaryWeatherActualDetailSelector = (state) => state.executiveReportDashboardReducer.summaryWeatherActualDetail;
export const summaryWeatherForecastBeforeReportDateSelector = (state) => state.executiveReportDashboardReducer.summaryWeatherForecastBeforeReportDate;
export const summaryWeatherForecastSelector = (state) => state.executiveReportDashboardReducer.summaryWeatherForecast;
export const summaryCoalToMineSelector = (state) => state.executiveReportDashboardReducer.summaryCoalToMine;
export const summaryPitToRomSelector = (state) => state.executiveReportDashboardReducer.summaryPitToRom;
export const summaryPitToPortSelector = (state) => state.executiveReportDashboardReducer.summaryPitToPort;
export const summaryRomToPortSelector = (state) => state.executiveReportDashboardReducer.summaryRomToPort;
// actual coal fleet
export const fleetFromPitSelector = (state) => state.executiveReportDashboardReducer.fleetFromPit;
export const summaryFleetFromPitSelector = (state) => state.executiveReportDashboardReducer.summaryFleetFromPit;
export const haulingFleetSelector = (state) => state.executiveReportDashboardReducer.haulingFleet;
export const summaryHaulingFleetSelector = (state) => state.executiveReportDashboardReducer.summaryHaulingFleet;
// end actual coal fleet
// plan coal fleet
export const planCoalFleetSelector = (state) => state.executiveReportDashboardReducer.planCoalFleet;
export const summaryPlanCoalFleetSelector = (state) => state.executiveReportDashboardReducer.summaryPlanCoalFleet;
// end plan coal fleet
// stockpile
export const summaryRomStockpileSelector = (state) => state.executiveReportDashboardReducer.summaryRomStockpile;
export const summaryPortStockpileSelector = (state) => state.executiveReportDashboardReducer.summaryPortStockpile;
// end stockpile
// barge
export const summaryBargeActualSelector = (state) => state.executiveReportDashboardReducer.summaryBargeActual;
export const summaryBargePlanSelector = (state) => state.executiveReportDashboardReducer.summaryBargePlan;
export const summaryBargePlanDetailSelector = (state) => state.executiveReportDashboardReducer.summaryBargePlanDetail;
export const summaryBargeStatusSelector = (state) => state.executiveReportDashboardReducer.summaryBargeStatus;
export const summaryBargeLoadProgressSelector = (state) => state.executiveReportDashboardReducer.summaryBargeLoadProgress;
// end barge
// transsipment
export const summaryTransshipmentActualMTDBLYTDBLSelector = (state) => state.executiveReportReducer.summaryTransshipmentActualMTDBLYTDBL;
export const summaryTransshipmentActualInRangeBeforeMTDBLYTDBLSelector = (state) => state.executiveReportReducer.summaryTransshipmentActualInRangeBeforeMTDBLYTDBL;
export const summaryTransshipmentActualInRangeMTDBLYTDBLSelector = (state) => state.executiveReportReducer.summaryTransshipmentActualInRangeMTDBLYTDBL;
export const summaryTransshipmentActualInRangeYearMTDBLYTDBLSelector = (state) => state.executiveReportReducer.summaryTransshipmentActualInRangeYearMTDBLYTDBL;
export const summaryTransshipmentActualBillOfLadingSelector = (state) => state.executiveReportDashboardReducer.summaryTransshipmentActualBillOfLading;
export const summaryTransshipmentActualSelector = (state) => state.executiveReportDashboardReducer.summaryTransshipmentActual;
export const summaryTransshipmentActualDetailSelector = (state) => state.executiveReportDashboardReducer.summaryTransshipmentActualDetail;
export const summaryTransshipmentNextSelector = (state) => state.executiveReportDashboardReducer.summaryTransshipmentNext;
export const summaryTransshipmentNextDetailSelector = (state) => state.executiveReportDashboardReducer.summaryTransshipmentNextDetail;
export const summaryTransshipmentFloatingCraneSelector = (state) => state.executiveReportDashboardReducer.summaryTransshipmentFloatingCrane;
export const summaryTransshipmentFloatingCraneDetailSelector = (state) => state.executiveReportDashboardReducer.summaryTransshipmentFloatingCraneDetail;
export const summaryTransshipmentForecastSelector = (state) => state.executiveReportDashboardReducer.summaryTransshipmentForecast;
export const summaryTransshipmentForecastDetailSelector = (state) => state.executiveReportDashboardReducer.summaryTransshipmentForecastDetail;
// end transshipment

// MTD
export const summaryMTDWeatherActualSelector = (state) => state.executiveReportDashboardReducer.summaryMTDWeatherActual;
export const summaryMTDWeatherActualDetailSelector = (state) => state.executiveReportDashboardReducer.summaryMTDWeatherActualDetail;
export const summaryMTDWeatherForecastSelector = (state) => state.executiveReportDashboardReducer.summaryMTDWeatherForecast;

// report
export const reportSelector = (state) => state.executiveReportDashboardReducer.report;

export default summarySlice.reducer;
